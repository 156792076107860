import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import AppContext from './contexts/AppContext'
import history from 'history.js'
import routes from './RootRoutes'
import { GlobalCss, MatxSuspense, MatxTheme, MatxLayout } from 'app/components'
import sessionRoutes from './views/sessions/SessionRoutes'
import AuthGuard from './auth/AuthGuard'
import { AuthProvider } from 'app/contexts/FirebaseAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import useAuth from 'app/hooks/useAuth'
import InsideApp from './InsideApp'

const App = () => {
    return (
        <AppContext.Provider value={{ routes }}>
            <SettingsProvider>
                <MatxTheme>
                    <GlobalCss />
                    <Router history={history}>
                        <AuthProvider>
                            <InsideApp />
                        </AuthProvider>
                    </Router>
                </MatxTheme>
            </SettingsProvider>
        </AppContext.Provider>
    )
}

export default App
