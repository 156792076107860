export var firebaseConfig = {
    apiKey: 'AIzaSyCwNiyjcmpElRudlby_7f_tZUrSkUc6C_0',
    authDomain: 'true-campus-295907.firebaseapp.com',
    projectId: 'true-campus-295907',
    storageBucket: 'true-campus-295907.appspot.com',
    messagingSenderId: '219100032360',
    appId: '1:219100032360:web:71562aa650b19826e1fadd',
}

export const auth0Config = {
    client_id: 'XmminWIs0S8gR3gIRBydYLWbF58x81vK',
    domain: 'matx.us.auth0.com',
}
