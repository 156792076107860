import React from 'react'
import useSettings from 'app/hooks/useSettings'
import Stelz from './Stelz_delivery_logo.jpeg'

const MatxLogo = ({ className }) => {
    const { settings } = useSettings()
    const theme = settings.themes[settings.activeTheme]

    return <img src={Stelz} alt="stelz_logo" width="40px" />
}

export default MatxLogo
