import React from 'react'
import { Redirect } from 'react-router-dom'
import invoiceRoutes from './views/invoice/invoiceRoutes'

import pagesRoutes from './views/pages/pagesRoutes'

const redirectRoute = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/pages/orders-amsterdam" />,
    },
]

const errorRoute = [
    {
        component: () => <Redirect to="/session/404" />,
    },
]

const routes = [
    ...pagesRoutes,
    ...invoiceRoutes,
    ...redirectRoute,
    ...errorRoute,
]

export default routes
