import React from 'react'

const pagesRoutes = [
    {
        path: '/pages/orders-amsterdam',
        component: React.lazy(() =>
            import('./orders/Open/Cities/Amsterdam/OrderListOpen')
        ),
    },
    {
        path: '/pages/orders-breda',
        component: React.lazy(() =>
            import('./orders/Open/Cities/Breda/OrderListOpen')
        ),
    },
    {
        path: '/pages/orders-leiden',
        component: React.lazy(() =>
            import('./orders/Open/Cities/Leiden/OrderListOpen')
        ),
    },
    {
        path: '/pages/orders-denbosch',
        component: React.lazy(() =>
            import('./orders/Open/Cities/DenBosch/OrderListOpen')
        ),
    },
    {
        path: '/pages/orders-eindhoven',
        component: React.lazy(() =>
            import('./orders/Open/Cities/Eindhoven/OrderListOpen')
        ),
    },
    {
        path: '/pages/orders-maastricht',
        component: React.lazy(() =>
            import('./orders/Open/Cities/Maastricht/OrderListOpen')
        ),
    },
    {
        path: '/pages/orders-rotterdam',
        component: React.lazy(() =>
            import('./orders/Open/Cities/Rotterdam/OrderListOpen')
        ),
    },
    {
        path: '/pages/orders-tilburg',
        component: React.lazy(() =>
            import('./orders/Open/Cities/Tilburg/OrderListOpen')
        ),
    },
    {
        path: '/pages/orders-utrecht',
        component: React.lazy(() =>
            import('./orders/Open/Cities/Utrecht/OrderListOpen')
        ),
    },
    {
        path: '/pages/orders-overig',
        component: React.lazy(() =>
            import('./orders/Open/Cities/Overig/OrderListOpen')
        ),
    },
    {
        path: '/pages/orders-delft',
        component: React.lazy(() =>
            import('./orders/Open/Cities/Delft/OrderListOpen')
        ),
    },
    {
        path: '/pages/orders-afgerond',
        component: React.lazy(() =>
            import('./orders/Finished/OrderListFinished')
        ),
    },
    {
        path: '/pages/dagtotalen',
        component: React.lazy(() => import('./dashboard/Analytics')),
    },
]

export default pagesRoutes
