import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import AppContext from './contexts/AppContext'
import history from 'history.js'
import routes from './RootRoutes'
import { GlobalCss, MatxSuspense, MatxTheme, MatxLayout } from 'app/components'
import sessionRoutes from './views/sessions/SessionRoutes'
import AuthGuard from './auth/AuthGuard'
import { AuthProvider } from 'app/contexts/FirebaseAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import useAuth from 'app/hooks/useAuth'

const InsideApp = () => {
    const { isAuthenticated, user } = useAuth()

    const client = new ApolloClient({
        uri: 'https://api.stelzdelivery.nl',
        cache: new InMemoryCache(),
        headers: {
            authorization: user?.id || null,
        },
    })

    return (
        <ApolloProvider client={client}>
            <MatxSuspense>
                <Switch>
                    {/* AUTHENTICATION PAGES (SIGNIN, SIGNUP ETC.) */}
                    {sessionRoutes.map((item, i) => (
                        <Route
                            key={i}
                            path={item.path}
                            component={item.component}
                        />
                    ))}
                    <AuthGuard>
                        <MatxLayout />
                    </AuthGuard>
                </Switch>
            </MatxSuspense>
        </ApolloProvider>
    )
}

export default InsideApp
